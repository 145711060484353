@font-face {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
}

body {
  outline: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  color: #4d565c !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  overflow-x: hidden;
}

.rowColorPillar {
  display: flex;
  align-items: center;
}

.paddingZero {
  padding: 0px !important;
}

.ms-DatePicker-table {
  width: 100% !important;
  height: 225px !important;
}

.ms-DatePicker-wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 30px !important;
}

.ms-DatePicker-picker {
  display: block;
}

.ms-DatePicker-goToday {
  margin-left: 8px !important;
}
/* 
.MuiDialog-root{
  z-index: 9999999 !important;
} */

/* .icon-nav{
 fill: #323130;
 width: 16px;
 height: 16px;
}

.icon-nav path{
 fill: #323130;
}

.ms-Nav-compositeLink[name=Pilares]:hover .icon-nav path{
 fill: #027636;
}

.ms-Nav-compositeLink[name=Empresas]:hover .icon-nav path{
 fill: #027636;
 background-color: black;
} */

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* This is what we are focused on */

/* .table-wrapper{
  overflow-y: scroll;
  height: 100%;
} */

/* .table-wrapper th{
    position: sticky;
    top: 0;
} */

/* A bit more styling to make it look better */

/* table{
  border-collapse: collapse;
  width: 100%;
} */

/* 
th{
    background: #DDD;
} */

/* td,th{
  padding: 10px;
  text-align: center;
} */

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #EAEAEA;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  opacity: 0.5;
  background-color: #bbbbbb;
}

.teamTable .ms-DetailsRow-fields {
  align-items: center !important;
}

.teamTable .ms-DetailsRow-check {
  height: 48px !important;
}

.calibrationTable {
  overflow: auto;
}

.meritTable .ms-DetailsHeader {
  padding-top: 5px;
}

.meritTable .ms-DetailsList-headerWrapper {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 48px;
  z-index: 99;
}

.calibrationTable .ms-DetailsHeader-cellIsCheck {
  background-color: #FFFFFF;
  position: sticky;
  left: 0;
  background: white;
  z-index: 9;
}

.transferirIcon i {
  transform: rotate(90deg);
}

#inputTextSmall {
  padding: 0 5px;
  height: 32px;
}

canvas {
  width: 100% !important;
}

.MuiPopover-root {
  z-index: 1000000 !important;
}

.MuiAutocomplete-popper {
  z-index: 1000000 !important;
}

.MuiDialog-root {
  z-index: 1000000 !important ;
}

.MuiCollapse-entered {
  min-height: auto !important;
}

.selectMultiMerito div.MuiOutlinedInput-input{
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.selectMultiMerito legend {
  max-width: 0px;
}