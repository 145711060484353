.nested {
  display: none;
}

.active {
  display: block;
}

.itemSelected {
  background-color: #E1DFDD !important;
  font-weight: 600 !important;
}

.itemSelected div[data-icon] i:first-child {
  background-color: #275937;
  opacity: 1;
  color: #FFFFFF;
}

.itemSelected div[data-icon] i:last-child {
  opacity: 1;
  color: #FFFFFF;
}

.itemSelected div[data-id] span{
  font-weight: 600;
}
